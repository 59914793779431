.topbar {
  width: 100%;
  background-color: $topbar-bg;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: calc(#{$topbar-height} + #{$menu-height / 2} + 20px);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: $box-shadow;

  .topbar-container {
    .logo-container {
      svg {
        width: 36px;
        height: auto;
        @include media-breakpoint-up(md) {
          width: 40px;
        }
      }
      .logo-name {
        font-size: 16px;
        font-weight: 300;
        @include media-breakpoint-up(md) {
          font-size: 26px;
          font-weight: 600;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    position: absolute;
    //margin-bottom: calc(#{$topbar-height} + 20px);
  }
  @include media-breakpoint-up(md) {
    &.topbar-secondary {
      & > .topbar-stick {
        background: rgba(250, 248, 247, 1);
        box-shadow: inset 35.4667px -35.4667px 35.4667px rgba(190, 188, 188, 0.125), inset -35.4667px 35.4667px 35.4667px rgba(255, 255, 255, 0.125);

        .topbar-container {
          .logo-container {
            .logo-name {
              color: $gray-6;
            }

            svg {
              color: $primary;
            }
          }
          .countdown-desktop {
            border-color: $secondary;
            color: $secondary !important;
            label {
              background: rgba(250, 248, 247, 1);
              color: $secondary;
            }
          }
          .container-already-voted {
            color: $gray-6;
            a {
              color: $primary;
            }
            .btn {
              width: 100px;
              margin-left: 14px;
              background: transparent;
              border: 1px solid $primary;
              color: $primary;
            }
          }
        }
      }
    }
  }

  .topbar-stick {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $topbar-height;
    background-color: $topbar-bg;
    z-index: 1000;
    //border-bottom: 1px solid white;

    .topbar-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 8px 16px 32px 16px;
      max-width: 100%;
      margin: auto;

      @include media-breakpoint-up(md) {
        height: 100%;
        padding: 0 30px;
        align-items: center;
      }

      .container-already-voted {
        color: white;
        a {
          color: white;
        }
      }

      @include container-boxed-responsive;

      .logo-container {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        color: white;

        @include media-breakpoint-up(md) {
          align-items: baseline;
        }

        .logo {
          margin-bottom: -4px;
          width: 46px;
        }

        .logo-university {
          position: absolute;
          display: flex;
          border-radius: 50%;
          left: 20px;
          top: 8px;
          padding: 1px;
          width: 24px;
          height: 24px;
          background-color: white;
          border: 2px solid $topbar-bg;

          @include media-breakpoint-up(md) {
            left: 20px;
            top: 8px;
            width: 28px;
            height: 28px;
          }

          img {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            @include media-breakpoint-up(md) {
              width: 22px;
              height: 22px;
            }
          }
        }

        .logo-name {
          text-align: center;
          color: white;
          font-size: 16px;
          line-height: 100%;
          font-weight: 300;
          margin-left: 20px;

          @include media-breakpoint-up(md) {
            font-size: 26px;
            font-weight: 500;
          }
        }
      }

      .countdown {
        color: white;
      }

    }
  }
}
