//
// _buttons.scss
//

button,
a {
  outline: none !important;
}

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-primary {
  background: linear-gradient(61.66deg, #EA6B24 23.26%, #FF8E0E 99.31%), #5C7AFF;

  &:hover {
    background: linear-gradient(61.66deg, #D15F1F 23.26%, #FF8D0C 99.31%), #5C7AFF;
  }
}

.btn-lg {
  padding: 12px 48px;
  font-size: 1rem;
  min-width: 170px;
}

.btn-link {
  background: none;
  border: none;

  &:hover {
    text-decoration: none;
  }

  &.btn-secondary:hover {
    background-color: transparent;
    color: $secondary-hover !important;
  }
}
.btn-link-secondary {
  color: $secondary;
}

.btn-outline-light {
  color: $gray-900;
}

.sticky-button-spacer {
  height: $sticky-button-height;
}

.sticky-button-submit {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: $sticky-button-height;
  z-index: 1000;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  .blur {
    position: absolute;
    inset: 0 0 0 0;
    background: rgba(255, 255, 255, 0.1);
    filter: blur(4px);
    backdrop-filter: blur(4px);
  }

  button {
    font-size: 1rem;
    padding: 12px 48px;
    min-width: 270px;
    z-index: 1000;

    &:disabled {
      background: #DBD7D7;
      color: #ADAAAA;
    }
  }
}
