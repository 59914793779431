.navigation-menu {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: $menu-height;
  padding: 10px 0;

  &.menu-topbar {
    margin-top: calc(#{$topbar-height} - #{$menu-height} / 2);
    position: absolute;
    background-color: $topbar-step-bg;
    border-radius: calc(#{$menu-height} / 2);
    box-shadow: $box-shadow;
    inset: 0 0 auto 0;
  }

  &.menu {
    position: relative;
    flex: 0 0 auto;
  }

  .menu-prorgession {
    position: absolute;
    left: 20%;
    right: 20%;
    background-color: $topbar-step-bg;
    height: 2px;
    top: calc(#{$menu-height} / 2);
    z-index: 900;

    &.progression-0 {
      background: $progression-color;
    }

    &.progression-2 {
      background: linear-gradient(90deg, $progression-color-completed 50%, $progression-color 50%);
    }

    &.progression-3 {
      background: $progression-color-completed;
    }
  }

  .menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 950;
    background-color: $topbar-step-bg;
    padding: 0 8px;

    .step {
      display: flex;
      justify-content: center;
      align-content: center;
      color: $progression-color;
      background-color: $topbar-step-bg;
      border: 1px solid $progression-color;
      border-radius: 50%;
      font-size: 12px;
      line-height: 18px;
      opacity: 0.5;
      width: 20px;
      height: 20px;

      @include media-breakpoint-up(md) {
        line-height: 20px;
        width: 22px;
        height: 22px;
      }
    }

    .name {
      color: $progression-color;
      opacity: 0.5;
      margin-left: 5px;
      font-size: 12px;
    }

    &.active {
      .step {
        border: 1px solid $progression-color-completed;
        background-color: $progression-color-completed;
        color: white;
        opacity: 1;
      }

      .name {
        color: $progression-text-active;
        opacity: 1;
      }
    }

    &.current {
      .step {
        border: 1px solid $progression-color-completed;
        background-color: $progression-color-completed;
        color: white;
      }

      .name {
        color: $progression-text-active;
        font-size: 14px;
        @include media-breakpoint-up(md) {
          font-size: 18px;
        }
      }
    }
  }
}

.container-navigation {
  button {
    margin: 10px 0;
    font-size: 14px;
    width: 152px;
    height: 42px;
    padding: 0;
  }
}
