#easy-tips {
  line-height: 120%;

  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
  }

  .title {
    position: relative;
    text-align: center;
    margin: 45px 0 25px;
    border-bottom: 1px solid #DBD7D7;
    font-size: 18px;
    font-weight: 600;

    @include media-breakpoint-up(md) {
      border-bottom: none;
      font-size: 20px;
      margin: 20px;
    }

    span {
      position: absolute;
      width: 150px;
      height: 22px;
      top: -10px;
      left: calc(50% - 75px);
      background-color: $bg-color;

      @include media-breakpoint-up(md) {
        position: relative;
        width: 100%;
        height: auto;
        inset: inherit;
        background-color: transparent;
        font-size: 22px;
      }
    }
  }

  .easy-tips-container {
    max-width: 270px;
    @include media-breakpoint-up(md) {
      max-width: 350px;
      min-width: 300px;
    }
  }

  .element {
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;
    padding: 8px;
    align-items: center;

    .text {
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      padding: 0 10px;

      @include media-breakpoint-up(md) {
        text-align: left;
        padding: 0;
      }

      strong {
        font-weight: 600;
      }
    }
  }

  .icon {
    width: 32px;
    height: 32px;
  }
  .icon-small {
    width: 26px;
    height: 26px;
  }
  .icon-cancel {
    position: absolute;
    width: 14px;
    height: 14px;
    @include media-breakpoint-up(md) {
      width: 18px;
      height: 18px;
    }
  }

  .container-text {
    display: flex;
    align-items: center;
    .text {
      font-size: 1rem;
      line-height: 120%;
    }
  }
}
