.icon-carded {
  display: flex;
  align-items: center;
  justify-content: center;

  .card {
    margin-bottom: 0;
    font-size: 40px;
    text-align: center;
    line-height: 100%;
  }
}
