@mixin box-input-md {
  @include media-breakpoint-up(md) {
    max-width: $max-width-input-md;
    margin: auto;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

$max-width-input-md: 320px;

.text-padded {
  margin: 0 !important;
  padding: 0 5px !important;
  font-size: 46px !important;
  font-weight: 700 !important;
}

.container-students {
  margin-top: 5px;
  display: flex;
  @include box-input-md;

  @include media-breakpoint-up(md) {
    flex-direction: column;
    align-items: start;
  }

  .no-participants {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    font-weight: 700;

    @include media-breakpoint-up(md) {
      flex-direction: column;
      align-items: start;
      width: 100%;
    }

    .icon-globe {
      font-size: 1.1rem;
      margin: 0;
    }

    span {
      margin-left: 10px;
      font-size: 1rem;
      line-height: 100%;
      font-weight: 400;
      @include media-breakpoint-up(md) {
        margin: 0;
      }
    }
  }

  .stats-students {
    font-size: 1rem;
    font-weight: normal;

    .container-logo {
      display: flex;
      align-items: center;
      margin-bottom: 6px;

      img {
        max-height: 30px;
        max-width: 30px;
        border-radius: 4px;
      }

      span {
        margin-left: 6px;
        font-size: 2rem;
        line-height: 120%;
        font-weight: 500;
      }
    }

    span {
      display: flex;
      font-size: 0.85rem;
      line-height: 120%;
    }
  }

  .list-students {
    position: relative;
    padding: 4px 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 70px;
    justify-content: space-evenly;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: start;
      margin-top: 10px;

      &:hover {
        cursor: pointer;
      }
    }

    &.blurred {
      padding: 4px;
      @include media-breakpoint-up(md) {
        flex-direction: inherit;
        justify-content: inherit;
      }
    }

    .blurred-box {
      position: absolute;
      inset: 0 0 0 0;
      background-color: white;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      opacity: 0.85;
      border-radius: 4px;
      border: 1px solid $secondary;
      color: $secondary;

      @include media-breakpoint-up(md) {
        border-color: $primary;
        color: $primary;
        text-align: left;
        padding: 16px;
      }
    }

    .student {
      margin-right: 12px;
      font-size: 0.75rem;
      @include media-breakpoint-up(md) {
        line-height: 110%;
        width: 45%;
      }

      &.blurred {
        filter: blur(13px);
      }
    }

    .discover-list {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 60px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background: -moz-linear-gradient(right, transparent 0%, $bg-color 50%); /* FF3.6-15 */
      background: -webkit-linear-gradient(right, transparent 0%, $bg-color 50%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, transparent 0%, $bg-color 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

      @include media-breakpoint-up(md) {
        height: 40px;
        width: 100%;
        inset: auto 0 0 0;
        background: -moz-linear-gradient(bottom, transparent 0%, $card-bg-color 70%); /* FF3.6-15 */
        background: -webkit-linear-gradient(bottom, transparent 0%, $card-bg-color 70%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, transparent 0%, $card-bg-color 70%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      }

      .icon {
        width: 20px;
        height: 20px;
        padding: 2px;
        border-radius: 50%;
        background-color: $secondary;
        color: white;
        margin-right: 10px;
      }
    }
  }
}

.container-available-votes {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @include box-input-md;

  @include media-breakpoint-up(md) {
    flex-direction: column;
    align-items: start;

    &:hover {
      cursor: pointer;
    }
  }

  .number {
    padding: 0 5px;
    font-size: 2rem;
    @include media-breakpoint-up(md) {
      padding: 0;
      line-height: 100%;
    }

    strong {
      color: $primary;
      font-weight: bold;
    }
  }

  .text {
    margin-left: 10px;
    font-size: 1rem;
    @include media-breakpoint-up(md) {
      font-size: 0.9rem;
      margin-left: 0;
    }
  }
}

.container-select-university,
.container-select-faculty {
  display: flex;
  flex-direction: column;
  @include box-input-md;

  label {
    width: 100%;
    font-size: 1.2rem;
    font-weight: 700;
  }
}

.rounded-bar {
  display: none;
  width: 162px;
  height: 4px;
  background-color: $primary;
  border-radius: 2px;
  margin: 10px 0;

  @include media-breakpoint-up(md) {
    display: block;
  }
}
