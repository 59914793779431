.card-talent {
  background: white;
  border-radius: 6px;
  margin: 0 15px;
  padding: 8px;

  .card-talent-header {
    display: flex;
    flex-direction: row;

    .talent-img {
      width: 100px;
      height: 100px;
      border-radius: 4px;
    }

    .talent-info {
      margin-left: 10px;
      padding: 6px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 100%;
      border-bottom: 1px solid #DBD7D7;

      .talent-university {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;

        font-size: 0.9rem;
        font-weight: 400;

        img {
          width: 20px;
          height: 20px;
          border-radius: 4px;
          margin-right: 10px;
        }
      }

      h4 {
        font-size: 1.1rem;
        font-weight: 600;
      }

      .fa-linkedin {
        font-size: 20px;
        line-height: 20px;
        width: 20px;
        text-align: center;
        color: $linkedin;
        margin-right: 10px;
      }
    }
  }

  .card-talent-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;

    @include media-breakpoint-up(md) {
      justify-content: space-evenly;
    }

    .wiraki {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100px;

      font-size: 0.85rem;
      font-weight: 400;

      svg {
        color: $primary;
        width: 30px;
        height: 30px;
        margin-right: 5px;
      }
    }

    .company {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      max-width: 200px;

      img {
        max-width: 36px;
        max-height: 36px;
        margin-right: 10px;
        @include media-breakpoint-up(md) {
          max-width: 52px;
          max-height: 52px;
        }
      }

      .company-info {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        font-size: 0.8rem;
        font-weight: 400;

        .company-name {
          font-size: 0.8rem;
          font-weight: 600;
          @include media-breakpoint-up(md) {
            font-size: 1rem;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    padding: 16px;
    min-height: 260px;

    @include media-breakpoint-up(lg) {
      padding: 34px;
    }


    .talent-name {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: $gray-5;
      text-align: center;
      width: 75%;
      margin: auto;
      border-bottom: 1px solid #ADAAAA;
      padding: 14px 0;
      font-weight: 600;

      svg {
        width: 25px;
        height: 18px;
        margin-right: 10px;
      }
    }

    .talent-body {
      padding-top: 28px;
      display: flex;
      justify-content: center;

      .talent-image {
        position: relative;
        margin-right: 24px;

        img {
          border-radius: 4px;
          width: 80px;
          @include media-breakpoint-up(lg) {
            width: 130px;
          }
        }

        .talent-linkedin {
          position: absolute;
          bottom: -1px;
          left: -1px;
          width: 43px;
          height: 37px;
          background-color: white;
          border-radius: 0 20px 0 3px;
          color: $linkedin;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
        }
      }

      .talent-data {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        font-size: 14px;
        color: $gray-5;

        @include media-breakpoint-up(lg) {
          font-size: 16px;
        }

        .light-gray {
          color: #8C8989;
          font-weight: 300;
        }

        img {
          width: 30px;
          margin-right: 12px;
        }
      }
    }
  }
}
