.welcome-title {
  font-size: 2rem;
}

.welcome-subtitle {
  font-weight: 700;
  font-size: 1.75rem;
}

.welcome-table-info {
  border-collapse: separate;
  border-spacing: 15px 15px;
  margin: 0 -15px 48px -15px;

  .welcome-list-info {

    .info-img {
      position: relative;
      white-space: nowrap;
      text-align: center;
      font-size: 1rem;
    }

    .info-text {
      font-size: 1rem;
      line-height: 120%;
    }
  }
}

.targets {
  font-size: 24px;
  font-weight: 700;
}

.welcome-box-info {

  padding: 24px;

  .title {
    text-align: center;
    font-size: 1.75rem;
  }

  .box-info {
    padding: 20px;

    .icon {
      text-align: center;
      font-size: 60px;
    }

    .text {
      text-align: center;
      font-size: 1rem;
      line-height: 120%;
      max-width: 130px;
      margin: auto;
    }
  }
}

#cards-talents {
  background: $secondary;
  padding: 25px 10px;

  h2 {
    color: $white;
    font-size: 1.75rem;
    margin: 0 1rem 1rem 1rem;
  }
}

#step-welcome {

  button {
    width: 280px;
    padding: 12px;
    font-size: 16px;
    line-height: 150%;
  }

  .container {
    height: auto;
  }

  .slider {
    margin-top: 20px;

    .box-welcome {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 100%;
      width: 70%;
      min-width: 350px;
      padding-bottom: 200px;

      button {
        width: 190px;
      }
    }

    .welcome-subtitle {
      font-size: 18px;
      font-weight: 400;
    }

    .box {
      border-radius: 24px;
      padding: 14px;
      margin-bottom: 24px;
      font-size: 18px;
      font-weight: 400;
      width: 100%;
      background-color: white;
      box-shadow: 0 10px 17px rgba(191, 191, 192, 0.15);

      @include media-breakpoint-up(md) {
        padding: 28px;
      }
    }

    .box-primary {
      background-color: $primary;
      color: white;
      box-shadow: 0 10px 17px rgba(72, 38, 5, 0.15);
    }

    .box-secondary {
      background-color: $secondary;
      box-shadow: 0 10px 17px rgba(19, 56, 219, 0.15);
    }

    .box-hello-future {
      display: flex;
      align-items: center;
      width: 100%;
      color: white;
      font-size: 20px;
      font-weight: 500;
      height: 120px;
      @include media-breakpoint-up(lg) {
        font-size: 28px;
      }

      &.flipped {
        justify-content: center;

        .wordBoost {
          width: 70px;

          @include media-breakpoint-up(sm) {
            width: 100px;
          }
        }
      }


      svg {
        line-height: 100%;
        margin: -10px 0;
        font-size: 40px;

        @include media-breakpoint-up(lg) {
          margin: -10px 0;
          font-size: 60px;
        }
        @include media-breakpoint-up(xl) {
          margin: -10px 0;
          font-size: 80px;
        }

      }
    }

    .text {
      padding-bottom: 14px;
      margin-bottom: 14px;
    }

    .box-steps {
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 120%;
      min-height: 250px;

      &.flipped {
        flex-direction: column;
        justify-content: space-evenly;

        .progress-bar {
          position: absolute;
          height: 70%;
          width: 1px;
          left: 34px;
          z-index: 0;

          @include media-breakpoint-up(sm) {
            left: 34px;
          }
          @include media-breakpoint-up(md) {
            left: 33px;
          }
          @include media-breakpoint-up(lg) {
            left: 34px;
          }
          @include media-breakpoint-up(xl) {
            height: 60%;
            left: 37px;
          }
        }
      }
    }

    .box-people {
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 220px;
      margin-bottom: 0;

      &.flipped {
        font-size: 16px;
        font-weight: 700;

        img {
          width: 150px;
        }
      }

      .container-students {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        padding-left: 20px;

        .student {
          display: inline-flex;
          border-radius: 50%;
          border: 2px solid white;
          margin-left: -20px;

          img {
            border-radius: 50%;
          }
        }
      }

      .text {
        margin-bottom: 0;
      }
    }

    .box-arrow {
      display: flex;
      align-items: center;
      justify-content: center;

      &.flipped {
        img {
          width: 115px;
          @include media-breakpoint-up(sm) {
            width: 130px;
          }
          @include media-breakpoint-up(md) {
            width: 115px;
          }
          @include media-breakpoint-up(lg) {
            width: 140px;
          }
          @include media-breakpoint-up(xl) {
            width: 170px;
          }
        }
      }
    }
  }

  .box-vote {

  }

  .how-it-works {
    margin-top: -200px;
    background-color: $secondary;

    h2 {
      padding: 48px 0;
      margin-bottom: 30px;
      font-size: 40px;
      font-weight: 700;
      color: white;
    }

    .container-howItWorks-card {
      margin: 0 -14px;

      .howItWorks-item {
        display: flex;
        flex-direction: column;
        flex: 0 0 25%;
        margin-bottom: -50px;

        .number {
          text-align: center;
          color: white;
          font-size: 20px;
          font-weight: 800;
          margin-bottom: 16px;
        }

        .howItWorks-card {
          background-color: white;
          box-shadow: 0 10px 17px rgba(191, 191, 192, 0.15);
          border-radius: 24px;
          padding: 24px;
          display: flex;
          flex-direction: column;
          margin: 0 14px;
          height: 100%;

          .title {
            text-align: center;
            color: $secondary;
          }

          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 76px;
            padding: 5px 0;

            span {
              line-height: 100%;
            }
          }

          .text {
            text-align: center;
            font-size: 15px;
          }

        }
      }
    }
  }

  .easy-tips {
    padding-top: 100px;
    padding-bottom: 80px;

    .title {
      margin-top: 96px;
      text-align: center;
      font-size: 40px;
      margin-bottom: 48px;
    }

    .easy-tips-container {
      display: flex;
      margin: 0 -16px;

      .easy-tips-item {
        flex: 0 0 25%;
        display: flex;

        .easy-tips-card {
          border: 1px solid #DBD7D7;
          padding: 24px;
          border-radius: 20px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 0 16px;
          width: 100%;

          .icon {
            font-size: 60px;
            text-align: center;
          }

          .text {
            text-align: center;
            font-size: 15px;
            font-weight: 400;
          }
        }
      }
    }
  }

  .talents {
    background-color: $secondary;
    color: white;
    padding: 86px 0;

    .title {
      text-align: center;
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 50px;
    }
  }
}

.two-box-container {

  &.container-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;

    h4 {
      font-size: 40px;
      font-weight: 700;
      margin: 20px 0;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      max-width: 300px;
    }
  }

  &.container-carousel {

    .carousel-card {
      display: flex;
      padding: 24px;
      align-items: center;
      background-color: white;
      border-radius: 6px;
      box-shadow: 0 10px 17px rgba(191, 191, 192, 0.15);
      min-height: 250px;
    }
  }
}
