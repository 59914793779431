.swiper-smooth {
  .swiper-wrapper {
    transition-timing-function: linear;
    display: flex;
    align-items: center;

    .swiper-slide {
      text-align: center;
      min-width: 180px;

      img {
        max-width: 150px;
        max-height: 50px;
      }
    }
  }

  &.universities {
    .swiper-wrapper {
      .swiper-slide {
        img {
          max-width: 150px;
          max-height: 150px;
        }
      }
    }
  }
}
