#page-form-vote {

  h1 {
    font-size: 1rem;
    text-align: center;
    color: $primary;
  }

  .input-box {
    max-width: 350px;
    width: 100%;
    display:flex;
    flex-direction: column;
    margin: auto;

    .inline-group {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      label {
        padding: 0;
        left: 15px;
        top: 8.52px;
        position: absolute;
        font-size: 0.85rem !important;
      }

      input {
        width: 100%;
        padding-left: 35px;
      }
    }
  }

  .inline-group {
    input {
      flex-grow: 1;
    }

    label {
      padding-left: 10px;
      padding-right: 10px;
      display: inline-flex;
    }
  }

  input, .autocomplete__placeholder, .autocomplete__input, .autocomplete__single-value, .autocomplete__menu {
    font-family: inherit !important;
    font-size: 0.85rem !important;
    text-align: left !important;
  }

  input, .autocomplete__input, .autocomplete__single-value {
    color: $gray-800;
  }

  .z-index-100 {
    z-index: 100 !important;
  }
  .autocomplete__menu {
    z-index: 100;

    mark {
      padding-left: 0;
      padding-right: 0;
      background-color: transparent;
      color: $primary;
    }
  }

  a.no-link:hover {
    cursor: inherit !important;
  }

  .container-logo-university {
    display: flex;
    justify-content: center;
    @include media-breakpoint-up(md) {
      justify-content: flex-end;
    }

    img {
      width: 60px;
      height: 60px;
      padding: 5px;
      background-color: white;
      border-radius: 5px;
      border: 1px solid $primary;
      margin-bottom: 10px;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }
  }

  .wizard .form-steps {
    ul {
      flex-direction: row;

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }

      li {
        a {
          justify-content: center;
          @include media-breakpoint-up(md) {
            justify-content: left;
          }
        }
      }
    }
  }

  button.vote-now {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1rem;
    margin: auto;

    svg {
      height: 1rem;
      width: 1rem;
      margin-right: 7px;
    }

  }
  label {
    color: $figma-gray-light;
    font-size: 0.6rem;
    margin-bottom: 0;
    display: block;
    text-align: left;

    @include media-breakpoint-up(lg) {
      font-size: 0.7rem;
    }
  }

  .text-votes-left {
    color: $primary;
    font-size: 0.7rem;
    margin-bottom: 0;

    span {
      color: $figma-gray-light;
      font-size: 0.6rem;
    }
  }

  .title-invited {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;

    svg {
      height: 0.8rem;
      width: 0.8rem;
      margin-right: 4px;
    }
  }

}
