.container-box-infos {
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
  flex-direction: row;

  .info-separator {
    display: flex;
    align-self: center;
    width: 3px;
    height: 60px;
    background-color: $primary;
    border-radius: 3px;
  }

  .box-infos {
    flex: 1 1 0;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 8px;
    text-align: center;

    .info-icon {
      color: $primary;
      font-size: 40px;
      line-height: 40px;
      font-weight: 600;
    }

    .info-text {
      color: $figma-gray;

      strong {
        font-weight: 600;
      }
    }
  }
}

.card.card-form {
  .card-body {
    padding: 10px;

    h6 {
      color: #ADAAAA;
      font-weight: 400;
      font-size: 0.85rem;
      @include media-breakpoint-up(md) {
        font-size: 16px;
        color: inherit;
      }
    }

  }
}
