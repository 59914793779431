h1 {
  font-size: 1.75rem;
  font-weight: 700;
}
h2 {
  font-size: 1.5rem;
  font-weight: 700;
}
h3 {
  font-size: 1.4rem;
  font-weight: 700;
}
h4 {
  font-size: 1.4rem;
  font-weight: 400;
}
h5 {
  font-size: 1.1rem;
}
.text-gray-light {
  color: $figma-gray-light !important;
}
.text-gray-normal {
  color: $figma-gray !important;
}
.text-gray-dark {
  color: $figma-gray-dark !important;
}

.bg-primary-yellow {
  background-color: $primary-yellow !important;
}
.h7 {
  font-size: 0.675rem;
}
.text-gold {
  color: #FFD700;
}
.text-silver {
  color: #C0C0C0;
}
.text-bronze {
  color: #CD7F32;
}
.text-primary-dark {
  color: $primary-dark !important;
}
.bg-primary-dark {
  background-color: $primary-dark !important;
}
.bg-primary-red {
  background-color: #FCEBEC !important;
}
.title {
  line-height: 120%;
  font-weight: 700;
  font-size: 26px;
}
.subtitle {
  line-height: 120%;
  font-weight: 400;
  font-size: 16px;
}
.caption {
  color: $figma-gray;
  font-weight: 300;
  //font-size: 0.75rem !important;
  font-size: 0.825rem !important;
}
.caption-small {
  color: $figma-gray;
  font-weight: 300; //normal
  //font-size: 0.625rem !important;
  font-size: 0.65rem !important;
}
.caption-highlight {
  color: $figma-gray-dark;
  font-weight: 300;
  //font-size: 0.75rem !important;
  font-size: 0.775rem !important;
}
.caption-big {
  font-size: 1rem;
}
.link {
  color: $primary;
  font-weight: 300;
  //font-size: 0.75rem !important;
  font-size: 0.825rem !important;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}
.clickable {
  &:hover {
    cursor: pointer;
  }
}
.bordered-primary {
  border: 1px solid $primary;
  border-radius: 10px;
}
.bordered-yellow-light {
  border: 1px solid #FFECC7;
  border-radius: 10px;
}
.bordered-success {
  border: 1px solid $success;
}
button.btn-primary {
  border: none;
  background-color: $primary-light;
  &:hover, &:focus {
    background-color: $primary-light-hover;
  }
}
