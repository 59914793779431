$input-space-left: 10px;

.container-form {
  .container-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    width: 100%;

    .input-icon {
      margin-left: 10px;
      font-size: 16px;
      color: #8C8989;

      &.primary {
        color: $primary;
      }
    }

    label {
      color: #ADAAAA;
      width: 100%;
      text-align: left;
      padding-left: $input-space-left;
      margin-bottom: 4px;
      font-size: 0.75rem;
    }

    .input {
      width: 100%;
      position: relative;
      padding-bottom: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.no-error-message {
        padding-bottom: 0;
      }

      .input-info {
        position: absolute;
        padding-left: $input-space-left;
        bottom: 0;
        left: 0;
        right: 0;
        font-size: 12px;
        line-height: 14.4px;
        color: #ADAAAA;

        &.error {
          color: $red;
        }
      }

      input {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        border-radius: 4px;
        padding: 10px;
        border-color: $input-border-color;
        color: $input-color;

        &:focus {
          border-color: $input-border-focus !important;
          box-shadow: $input-border-focus-filter;
        }

        &.form-control.invalid {
          border-color: $red;
        }
        &.form-control.valid {
          background: none;
          border-color: $input-border-color-valid;
        }
        &.form-control:focus {
          border-color: $primary;
        }
      }

      .select-box {
        &.valid {
          & > .autocomplete__control {
            border-color: $input-border-color-valid !important;
            .autocomplete__indicator {
              color: $input-border-color-valid !important;
            }
          }
        }

        &.invalid {
          & > .autocomplete__control {
            border-color: $red !important;
            .autocomplete__indicator {
              color: $red !important;
            }
          }
        }
      }
    }
  }

  .error-message {
    font-weight: 400;
    color: $red;
    font-size: 0.9rem;
  }

  button {
    margin: 1.2rem 0;
    padding: 10px 50px;
  }
}

// GENERAL SELECT DROPDOWN
.autocomplete__control {
  border-color: $input-border-color !important;

  &.autocomplete__control--is-focused {
    border-color: $input-border-focus !important;
    box-shadow: $input-border-focus-filter;

    .autocomplete__input input {
      box-shadow: none !important;
    }
  }

  .autocomplete__value-container {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .autocomplete__indicator {
    color: $input-border-color;
  }
}

.no-pipe-dropdown .autocomplete__indicator-separator {
  display: none;
}
.autocomplete__menu, .autocomplete__menu * {
  z-index: 10000 !important;
}
.autocomplete__single-value .icon {
  display: none;
}
.highlight-result {
  padding: 0 !important;
  background-color: transparent !important;
  color: $primary !important
}
.highlight-selected-result {
  background-color: black !important;
}
