.container-select-attending-year {
  .inline-select {
    display: inline-block;
    width: 50%;

    &:first-of-type > div:first-of-type {
      border-right: none;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    &:last-of-type > div:first-of-type {
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .separator {
    z-index: 2000;
    margin-left: -1px;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    background-color: $input-border-color;
    margin-bottom: 8px;
    margin-top: 8px;
    width: 1px;
    box-sizing: border-box;
  }
}
