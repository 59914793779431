.container-select {
  margin-bottom: 20px;

  label {
    font-size: 1.1rem;
    line-height: 1.1rem;
    margin-bottom: 6px;
  }
}

.container-with-sticky-button {
  margin-bottom: calc(#{$sticky-button-height} + 20px);
}

.container-sticky-button {
  padding-bottom: $sticky-button-height;
}
