/*
  GLOBAL
*/
.card {
  border-radius: 6px;
  background-color: $card-bg-color;
  box-shadow: $box-shadow;
  margin-bottom: 17px;

  h3.title {
    font-size: 0.75rem;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
    font-weight: 700;
  }

  .card-body {
    padding: 16px;
  }
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;
}
.card-how-it-works {
  border: none;
  padding: 10px 0;
  border-radius: 12px;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;

  @include media-breakpoint-up(sm) {
    width: 45%;
    flex-direction: column;
    padding: 25px;
  }
  @include media-breakpoint-up(lg) {
    width: 22%;
  }

  .icon {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    justify-self: flex-start;
    aspect-ratio: 1;
    margin-right: 10px;

    @include media-breakpoint-up(sm) {
      width: 56px;
      height: 56px;
      margin-right: 0;
    }

    i {
      font-size: 0.7rem;
      @include media-breakpoint-up(sm) {
        font-size: 1.2rem;
      }
    }
  }

  p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: $figma-gray;
    text-align: left;
    font-size: 0.7rem;

    @include media-breakpoint-up(sm) {
      margin-top: 1rem;
      margin-bottom: 0;
      text-align: center;
      font-size: 0.8rem;
    }
  }
}
.privacy-terms {
  color: $figma-gray-light;
  font-size: 0.6rem;
}
