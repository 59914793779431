.modal-footer {
  &.button-divided {
    justify-content: space-between;
  }
}

.modal-mobile-full {
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 4rem;

  .modal-dialog {
    height: 100%;
    display: flex;
    margin-bottom: 0;
    margin-top: 0;
    z-index: 5000;

    .modal-content {
      border-radius: 24px 24px 0 0;
      background-color: #FEFAE7;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      border-width: 0;

      &::-webkit-scrollbar {
        display: none;
      }

      .modal-body {
        padding-top: 0;
      }

      h6 {
        margin: 10px 0 20px 0;
        color: $figma-gray-light;
        font-weight: 300;
        font-size: 0.650rem;
      }
    }
  }
}

#modal-referral {
  margin: 0 25px;

  .modal-content {
    background: rgba(255, 252, 250, 0.1);
    box-shadow: 0 11px 14px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(50px);
  }

  .modal-title {
    color: white;
  }

  .modal-text {
    color: white;
    font-weight: 400;
    font-size: 1rem;
  }


  svg {
    color: $primary;
    height: 3rem;
    width: 3rem;
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

#modal-step-blocked {
  max-width: 280px;
  margin: auto;

  @include media-breakpoint-up(md) {
    max-width: 460px;
  }

  .modal-title {
    font-size: 18px;
    font-weight: 700;
  }

  img {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 185px;
  }

  .modal-text {
    font-size: 16px;
  }
}

#modal-participants {
  .modal-body {
    padding: 36px 24px 16px 24px;

    .modal-title {
      font-size: 22px;
      margin-bottom: 30px;
    }

    .student {
      font-size: 14px;
    }

    .modal-text {
      margin-top: 36px;
      font-size: 18px;
      font-weight: 400;
    }

    button {
      font-weight: 700;
    }
  }
}
#modal-reward {
  .modal-body {
    padding: 36px 24px 16px 24px;

    .modal-title {
      font-size: 22px;
      margin-bottom: 30px;
    }

    .modal-text {
      margin-top: 36px;
      font-size: 18px;
      font-weight: 400;
    }

    button {
      font-weight: 700;
    }
  }
}
#modal-countdown {
  .modal-body {
    padding: 36px 24px 16px 24px;

    .modal-title {
      font-size: 16px;
      max-width: 200px;
      margin: 0 auto 30px auto;
    }

    img {
      width: 270px;
      margin-bottom: 30px;
    }

    .modal-text {
      margin: 36px auto 0 auto;
      font-size: 14px;
      font-weight: 400;
      max-width: 250px;
    }

    button {
      font-weight: 700;
    }
  }
}
