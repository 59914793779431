.box-countdown {
  text-align: center;
  margin: 0.5rem;
  font-size: 0.7rem;
  color: $figma-gray;
}

.countdown {
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: center;
  position: relative;

  .countdown-element {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 4px;

    .number {
      font-size: 1.6rem;
    }

    .text {
      font-size: 0.8rem;
      font-weight: 300;
    }
  }

  &.countdown-compact {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 6px 16px;
    font-size: 0.9rem;
    line-height: 100%;
    border: 1px solid white;
    border-radius: 15px;

    span {
      margin: 0 2px;
    }

    svg {
      width: 0.9rem;
      height: 0.9rem;
      margin-right: 2px;
      line-height: 100%;
    }

    &.countdown-desktop {
      border-radius: 4px;
      padding: 10px 20px 6px 20px;
      font-size: 1rem;
      font-weight: 400;

      label {
        position: absolute;
        top: -9px;
        left: calc(50% - 55px);
        width: 110px;
        padding: 4px 0;
        text-align: center;
        font-size: 10px;
        line-height: 10px;
        background-color: $primary;
        margin: 0;
      }

      span {
        margin: 0 4px;
      }
    }
  }
}
