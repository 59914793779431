@each $value in $box-widths {
  .w-#{$value} {
    display: flex;
    width: $value * 1%;
    flex-basis: $value * 1%;
  }
}
@each $size in $fs {
  .fs-#{$size} {
    font-size: #{$size}px;
  }
}
