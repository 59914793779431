$container-max-width-sm: 600px;
$container-max-width-md: 800px;
$container-max-width-lg: 900px;
$container-max-width-xl: 1100px;


@mixin container-boxed-responsive {
  @include media-breakpoint-up(sm) {
    max-width: $container-max-width-sm;
  }
  @include media-breakpoint-up(md) {
    max-width: $container-max-width-md;
  }
  @include media-breakpoint-up(lg) {
    max-width: $container-max-width-lg;
  }
  @include media-breakpoint-up(xl) {
    max-width: $container-max-width-xl;
  }
}

.container {
  padding: 0 30px;
  @include container-boxed-responsive;
}

.box-content-card {
  & > * {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
  }
}

.border-right {
  border-right: 1px solid #DBD7D7;
}

html, body, #root {

  @include media-breakpoint-up(md) {
    height: 100%;
  }
}

.main-content {
  @include media-breakpoint-up(md) {
    height: 100%;
    padding-bottom: 60px;
  }
}

.container-with-sticky-topbar {
  @include media-breakpoint-up(md) {
    display: flex;
    height: 100vh;
    padding-top: calc(#{$topbar-height} + 40px);
    padding-bottom: 60px;
  }
}

.main-card {
  width: 100%;
  @include media-breakpoint-up(md) {
    height: 700px;
  }
}

footer {
  background-color: #4D4B4B;
  color: white;

  .logo {
    font-size: 26px;
    font-weight: 600;
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
      width: 40px;
      height: 40px;
    }
  }
}

.title-container {
  text-align: center;
  padding: 24px 48px;
}

#layout-desktop {
  .btn-back {
    color: $gray-5;
  }

  .btn-inactive {
    color: $gray-2;

    &.btn-link {
      background-color: transparent;
      border: none;
    }

    &.btn-secondary {
      background-color: $gray-1;
      border-color: $gray-1;
      font-weight: 500;
    }
  }
}

#layout-mobile {
  .btn-inactive {
    background: $gray-1;
    border-color: $gray-1;
    font-weight: 500;
  }
}

.bg-secondary-light {
  background-color: $secondary-light;
}
